<template>
  <div>
      <b-button v-if="prvCanEditCompetition"><b-icon icon="gear"/> Compétition</b-button>
      <b-button v-if="prvCanEditOrRunCompetition"><b-icon icon="play"/> Compétition</b-button>
      <b-button v-if="prvCanPrepareRound"><b-icon icon="gear"/>Manche</b-button>
      <b-button v-if="prvCanPrepareEvent"><b-icon icon="gear"/>Epreuve</b-button>
  </div>
</template>

<script>

export default {

  computed:{
    prvCanEditCompetition(){ return true; },
    prvCanPrepareRound(){ return true; },
    prvCanPrepareEvent(){ return true; },
  }
}
</script>
